import {
  EditorReadyOptions,
} from '@wix/platform-editor-sdk';

export async function getCurrentVersion(editorReadyOptions: EditorReadyOptions) {
  const scriptUrl: string =
    editorReadyOptions?.initialAppData?.appFields?.platform?.viewerScriptUrl ||
    editorReadyOptions?.initialAppData?.appFields?.platform?.editorScriptUrl ||
    editorReadyOptions?.initialAppData?.editorScriptUrl;
  return scriptUrl.match(/[0-9]+.[0-9]+.[0-9]+/g)[0];
}
